import { useContext, useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap-icons/font/bootstrap-icons.css";
import Calendar from "./Calendar";
import clock_image from "../assets/Clock.png";
import { Link, useNavigate } from "react-router-dom";
import {
    CITIZENS_HEARING_PROGRAM_ROUTE,
    EUROPEAN_PROJECTS_ROUTE,
    MAIN_BANNER_INFO,
    PRESIDENTS_MESSAGE_ROUTE,
} from "../utils/consts";
import { fetchMainBanner, fetchOneMayorMessage, fetchProjects } from "../http/activitiesAPI";
import { Context } from "..";
import { Spinner } from "react-bootstrap";

const Activities = () => {
    const navigate = useNavigate();
    const { activities } = useContext(Context);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchMainBanner()
            .then((data) => {
                activities.setMainBanner(data);
            })
            .then(() =>
                fetchOneMayorMessage(1)
                    .then((data) => {
                        activities.setMayorMessage(data);
                    })
                    .then(() =>
                        fetchProjects()
                            .then((data) => {
                                activities.setProjects(data);
                            })
                            .finally(() => setLoading(false))
                    )
            );
    }, []);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={"grow"} />
            </div>
        );
    }

    return (
        <div className="d-flex flex-xl-row flex-column justify-content-center">
            <div className="d-flex flex-xl-row flex-wrap justify-content-center px-3 py-4 col-xl-9 col-12 flex-column pe-xl-2">
                <Carousel className="col-xl-9 col-12" fade>
                    {activities.mainBanner.count > 0
                        ? activities.mainBanner.rows.map((item, idx) => (
                              <Carousel.Item
                                  key={idx}
                                  className="h-100 rounded"
                                  style={{
                                      background: `url(${
                                          process.env.REACT_APP_API_URL + item.img
                                      })  center center/cover no-repeat`,
                                      minHeight: "75vh",
                                      backgroundSize: "cover !important",
                                      height: "100%",
                                  }}
                              >
                                  <Carousel.Caption>
                                      <h3 className="text-start truncate">{item.title}</h3>
                                      <div
                                          role="button"
                                          onClick={() => navigate(MAIN_BANNER_INFO + "/" + item.id)}
                                          className="d-flex justify-content-start align-items-center my-3 text-decoration-none text-white ps-4"
                                      >
                                          Citește mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
                                      </div>
                                  </Carousel.Caption>
                              </Carousel.Item>
                          ))
                        : null}
                </Carousel>
                <div
                    className="flex-column d-xl-flex d-none col-xl-3 ps-xl-2 pt-3 pt-xl-0 carousel-side-news"
                    style={{ maxHeight: "75vh" }}
                >
                    {activities.mayorMessage ? (
                        <div
                            onClick={() => navigate(PRESIDENTS_MESSAGE_ROUTE)}
                            role="button"
                            className="d-flex flex-column rounded h-100 text-center"
                            style={{
                                background: `url(${
                                    process.env.REACT_APP_API_URL + activities.mayorMessage.img
                                }) center center/cover no-repeat`,
                            }}
                        >
                            <span className="d-flex justify-content-start align-items-center my-3 text-decoration-none text-white text-wrap px-4 bg-primary text-center font-2">
                                Mesaj de Bun Venit
                            </span>
                            <Link
                                to={PRESIDENTS_MESSAGE_ROUTE}
                                className="d-flex justify-content-start align-items-center my-1 text-decoration-none text-white ps-4"
                            >
                                Citește mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
                            </Link>
                        </div>
                    ) : null}
                    <div
                        onClick={() => navigate(CITIZENS_HEARING_PROGRAM_ROUTE)}
                        role="button"
                        className="d-flex flex-column rounded h-100 text-center my-2"
                        style={{
                            background: `url(${clock_image}) center center/cover no-repeat`,
                        }}
                    >
                        <span className="d-flex justify-content-start align-items-center my-3 text-decoration-none text-white text-wrap px-4 font-2">
                            Plătește factura
                        </span>
                        <Link
                            to={CITIZENS_HEARING_PROGRAM_ROUTE}
                            className="d-flex justify-content-start align-items-center my-1 text-decoration-none text-white ps-4"
                        >
                            Citește mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
                        </Link>
                    </div>
                    <div
                        onClick={() => navigate(EUROPEAN_PROJECTS_ROUTE)}
                        role="button"
                        className="d-flex flex-column rounded h-100"
                        style={{
                            background: `url("https://st.depositphotos.com/2735675/4352/i/450/depositphotos_43528703-stock-photo-moldova-flag-waving-on-the.jpg") center center/cover no-repeat`,
                        }}
                    >
                        <span className="d-flex justify-content-start align-items-center my-3 text-decoration-none text-white text-wrap px-4 font-2">
                            Proiecte
                        </span>
                        <Link
                            to={EUROPEAN_PROJECTS_ROUTE}
                            className="d-flex justify-content-start align-items-center my-1 text-decoration-none text-white ps-4"
                        >
                            Citește mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
                        </Link>
                    </div>
                </div>
            </div>
            <Calendar />
        </div>
    );
};

export default Activities;
